import React, { Component } from 'react';
import { Slide } from 'spectacle';

import Header from '../components/Header';
import HexBG from '../components/HexBG';

import Tooltip from '../components/Tooltip'
import Navigation from '../components/Navigation'
import Animation from '../components/AnimationContainer'

export default class WhereInTheWorld extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false
    };
    this.handleStatus = this.handleStatus.bind(this);
  }
  handleStatus() {
    this.setState(state => ({
      showStatus: !state.showStatus
    }));
  }

  render() {
    return (
      <Slide transition={['fade']} bgColor='transparent'>
        <div className='slide03'>
          <Header />
          <Animation title="Where in the World" animation="8" />
          <Navigation prev="Bad Guys" next="Bring it with us" />
          <Tooltip
            title="Where in the World?"
            content="
            Moving to more localized content for an international audience will require some changes to how the website is accessed. Our goal is to make it as easy as possible for users to see up-to-date, rich content in their own region and language, with their own messaging, without even needing to click.
            "
          />
          <button className='more-info' onClick={this.handleStatus}>
          </button>
          <p className="i">i</p>
          {this.state.showStatus ? (
            <div className='slide-in'>
              <div className='expando'>
                <div className='expando-content'>
                  <h1>Where in the World?</h1>
                  <p>
                    Currently, Armstrong makes use of subdomains to handle most of its website. For example, Armstrong
                    University is at education.armstronginternational.com, and Concept is at
                    concept.armstronginternational.com. The yellow copy indicates the subdomain portion of the url.
                    These third party applications can stay where they are, and should not be affected by a change to
                    the website and continue to use subdomains as their urls.
                  </p>
                  <ul>
                    <li>
                      Armstronginternational.com will become the Hub Site, feeding various English content to its 5
                      children, the Regional Sites: EMEA, China, India, Korea and Japan/Pac Rim.
                    </li>
                    <li>
                      The main difference in structure for urls will be for Regional Sites, which we recommend
                      associating with a relevant top level domain (tld), such as .eu, .jp, .cn etc. As an example, the
                      Chinese site would use armstronginternational.cn.
                    </li>
                    <li style={{paddingBottom: 0}}>
                      Each Regional Site may also have multiple languages; certainly, the regional language and English
                      as a minimum, but for some, many more, as in the case of EMEA. Where multiple languages are
                      present, the url will work as follows:
                      <ul>
                        <li>EMEA in English – Armstronginternational.eu/en</li>
                        <li>EMEA in French – Armstronginternational.eu/fr</li>
                        <li>EMEA in German – Armstronginternational.eu/de</li>
                        <li>China in Simplified Chinese – Armstronginternational.cn</li>
                      </ul>
                    </li>
                    <li>
                      Users would be served the correct language based on their browser language settings and location.
                    </li>
                    <li>
                      Users who type in the .com or Hub Site url, but who are in a region other than the Americas, would
                      hit a regional landing page, informing them that there is a Regional Site with messaging and
                      content pertinent to their location, while also giving them the option of going to the .com site,
                      if desired.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <HexBG />
      </Slide>
    );
  }
}
