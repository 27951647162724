import React, { Component } from 'react';
import { Slide } from 'spectacle';

import Header from '../components/Header';
import HexBG from '../components/HexBG';

import Navigation from '../components/Navigation';

export default class OtherWorlds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false
    };
    this.handleStatus = this.handleStatus.bind(this);
  }
  handleStatus() {
    this.setState(state => ({
      showStatus: !state.showStatus
    }));
  }

  render() {
    return (
      <Slide transition={['fade']} bgColor='transparent'>
        <div className='slide02'>
          <Header />
          <div className='slide-input'>
            <div className='slide-title stacked'>
              <div>
                <h2>Other Worlds</h2>
              </div>
            </div>
            <div className='slide-content'>
              <p>
                A number of other sites and tools exist outside of the WordPress and Salesforce galaxies. We won’t
                forget them.
              </p>
            </div>
          <button className='more-info' onClick={this.handleStatus}>
          </button>
          <p className="i">i</p>
            {this.state.showStatus ? (
              <div className='slide-in'>
                <div className='expando'>
                  <div className='expando-content'>
                    <ul>
                      <li>
                        Concept – The opportunity may exist to tie specific products more closely to Concept. This was
                        the original intent of the site when it was upgraded and redesigned in 2015, and as a minimum we
                        would envision linking products in the new site via url to their Concept-appropriate page in the
                        configuration tool.
                      </li>
                      <li>
                        Hospital Utilities PWA – This small suite specific to China’s healthcare industry was built as a
                        WordPress site and can be migrated into the new Multisite and administered accordingly
                      </li>
                      <li>
                        The Brain® / Pipe Up! PWA – Currently undergoing a redesign and content update, this app will be
                        engineered to push information directly to Salesforce/Pardot.
                      </li>
                      <li>HR Connect</li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <Navigation prev='Armstrong University' next='How Long' />
        </div>
        <HexBG />
      </Slide>
    );
  }
}
