import React, { Component } from 'react';
import { Slide } from 'spectacle';

import Header from '../components/Header';
import HexBG from '../components/HexBG';

import Tooltip from '../components/Tooltip'
import Navigation from '../components/Navigation'
import Animation from '../components/AnimationContainer'

export default class KeepAlive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false
    };
    this.handleStatus = this.handleStatus.bind(this);
  }
  handleStatus() {
    this.setState(state => ({
      showStatus: !state.showStatus
    }));
  }

  render() {
    return (
      <Slide transition={['fade']} bgColor='transparent'>
        <div className='slide03'>
          <Header />
          <Animation title="Keeping the Site Alive" animation="12" />
          <Navigation prev="Products" next="Word on the Street" />
          <Tooltip
            title="Keeping the Site Alive"
            content="
            Content is king. Providing your site admins and content managers with easy but controlled ways of keeping content up to date and relevant is key to improving SEO and ensuring that your users come back.
            "
          />
          <button className='more-info' onClick={this.handleStatus}>
          </button>
          <p className="i">i</p>
          {this.state.showStatus ? (
            <div className='slide-in'>
              <div className='expando'>
                <div className='expando-content'>
                  <h1>Keeping the Site Alive</h1>
                  <ul>
                    <li>
                      WordPress and Salesforce working together allows us to de-couple logging in to the site, with
                      logging in to your user portal. A user who needs to edit a site’s content will gain access from a
                      different location than someone who wants to visit their user portal.
                    </li>
                    <li>
                      Site admins have different levels of access, all controlled by super admins. Super admins see
                      everything, and regular admins will be granted access and change capabilities for one or more
                      sites within the Multisite network. Any number of other roles can be established to grant or
                      restrict access to content managers or other specialists who should only see certain pieces of the
                      network of sites.
                    </li>
                    <li style={{paddingBottom: 0}}>
                      Examples of these roles could be:
                      <ul>
                        <li>
                          – A translation manager could only be able to create translation jobs in a certain language on
                          a Regional Site.
                        </li>
                        <li>
                          – An editor role could be set up to allow a single user to write and publish Daily
                          Devotionals, only on the Hub Site.
                        </li>
                        <li>
                          – Updates to content are as simple as filling out a form and clicking a button for most edits,
                          without the need for any code experience.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Site admins and the flexibility of this structure works well within Armstrong’s regional structure
                      and is able to track along with the capacity and skill of the employees.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <HexBG />
      </Slide>
    );
  }
}
