import React, { Component } from 'react';
import { Slide } from 'spectacle';

import Header from '../components/Header';
import HexBG from '../components/HexBG';

import Tooltip from '../components/Tooltip'
import Navigation from '../components/Navigation'
import Animation from '../components/AnimationContainer'

export default class WhyWordpress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false
    };
    this.handleStatus = this.handleStatus.bind(this);
  }
  handleStatus() {
    this.setState(state => ({
      showStatus: !state.showStatus
    }));
  }

  render() {
    return (
      <Slide transition={['fade']} bgColor='transparent'>
        <div className='slide03'>
          <Header />
          <Navigation prev="Hubs" next="Best In Class" />
          <Animation title="Why Wordpress" animation="6" />
          <Tooltip
            title="Why Wordpress"
            content="
            Powering 34% of all websites and 61% of all CMS, WordPress is the industry-leading platform for managing your content. You’ll be in good company, too. Mercedes-Benz, the New York Times, U of M and even The White House sites are all running on WordPress.
            "
          />
          <button className='more-info' onClick={this.handleStatus}>
          </button>
          <p className="i">i</p>
          {this.state.showStatus ? (
            <div className='slide-in'>
              <div className='expando'>
                <div className='expando-content'>
                  <h1>Why WordPress</h1>
                  <ul>
                    <li>It’s free – With the exception of some pro plugins that will require a small annual fee.</li>
                    <li>
                      Ease of use – WordPress is significantly more user-friendly, especially for administrators and
                      non-developers.
                    </li>
                    <li>
                      Extensibility – WordPress’ third-party theme and plugin communities make it similarly easy to
                      extend without the need for custom development.
                    </li>
                    <li>
                      Ease of getting help – WordPress has a massive global community, making it easy to find support
                      for any issues that arise.
                    </li>
                    <li>
                      Lower development costs – WordPress offers more out-of-the-box solutions and WordPress developers
                      are everywhere.
                    </li>
                    <li>Familiarity – Many admins are very familiar with how the WordPress admin is set up.</li>
                  </ul>
                  <p>
                    Fairly Painless has built over 60 WordPress sites, including several large WordPress Multisites,
                    which is the direction we would take with this build. We use a bare minimum of plugins in order to
                    keep updates and potential security holes to a minimum, and always build custom themes for our sites
                    rather than using purchased templates filled with unnecessary functionality or code. In addition, we
                    design our admin screens as well as the site, so administering the site’s content is a more
                    enjoyable and rapid experience.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <HexBG />
      </Slide>
    );
  }
}
