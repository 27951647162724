import React, { Component } from 'react';
import { Slide } from 'spectacle';

import Header from '../components/Header';
import HexBG from '../components/HexBG';

import Navigation from '../components/Navigation';

export default class Maintenance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false
    };
    this.handleStatus = this.handleStatus.bind(this);
  }
  handleStatus() {
    this.setState(state => ({
      showStatus: !state.showStatus
    }));
  }

  render() {
    return (
      <Slide transition={['fade']} bgColor='transparent'>
        <div className='slide02 maintenance-slide'>
          <Header />
          <div className='slide-input'>
            <div className='slide-title stacked'>
              <div>
                <h2>What About Maintenance?</h2>
              <p className='fade-step-1'>
                Compared to the Drupal site, yearly maintenance by outside parties for the new WordPress site is
                expected to cost roughly 80% less.
              </p>
              </div>
            </div>
            <div className='slide-content'>
              <h5 className='drupal-costs' style={{marginBottom: '16px'}}>Drupal Site External Maintenance Costs</h5>
              <table>
                <tr>
                  <td>2016</td> <td>$37,000</td>
                </tr>
                <tr>
                  <td>2017</td> <td>$61,000</td>
                </tr>
                <tr>
                  <td>2018</td> <td>$35,000</td>
                </tr>
                <tr>
                  <td>2019 (January - October)</td> <td>$35,000</td>
                </tr>
              </table>
              <p>
                As long as the site remains on Drupal, these yearly, third-party maintenance expenses will continue at a
                similar rate.
              </p>
            </div>
            <button className='more-info' onClick={this.handleStatus}></button>
            <p className='i'>i</p>
            {this.state.showStatus ? (
              <div className='slide-in'>
                <div className='expando'>
                  <div className='expando-content'>
                    <ul>
                      <li>The new WordPress site is expected to have a lifetime of several years.</li>

                      <li>
                        Updates and regional changes will be easier to manage and require less time, resulting in a
                        reduction of Armstrong's internal costs for site administration.
                      </li>

                      <li>
                        The new Armstrong Multisite will serve as a smoothly functioning sales tool, providing global
                        customers and representatives with quick, easy access to updated information—and a much more
                        enjoyable experience.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <Navigation prev='How Long' next='Takeaway' />
        </div>
        <HexBG />
      </Slide>
    );
  }
}
