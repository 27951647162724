import React, { Component } from 'react';
import { Slide } from 'spectacle';

import Header from '../components/Header';
import HexBG from '../components/HexBG';

import Tooltip from '../components/Tooltip';
import Navigation from '../components/Navigation';
import Animation from '../components/AnimationContainer';

export default class WordOnTheStreet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false
    };
    this.handleStatus = this.handleStatus.bind(this);
  }
  handleStatus() {
    this.setState(state => ({
      showStatus: !state.showStatus
    }));
  }

  render() {
    return (
      <Slide transition={['fade']} bgColor='transparent'>
        <div className='slide03'>
          <Header />
          <Animation title='Word on the Street' animation='13' />
          <Navigation prev='Keeping the Site Alive' next='Salesforce' />
          <Tooltip
            title='The Word on the Street'
            content="
            Each Regional Site will be available in the languages appropriate for that region and managed by the region's admin.
            "
          />
          <button className='more-info' onClick={this.handleStatus}></button>
          <p className='i'>i</p>
          {this.state.showStatus ? (
            <div className='slide-in'>
              <div className='expando'>
                <div className='expando-content'>
                  <h1>Word on the Street</h1>
                  <ul>
                    <li>
                      Each site in the network can have its own admins, who can only edit their “owned” site. When a new
                      site is cloned, it will be in English and translations can follow. Each site can be translated in
                      as many languages as needed.
                    </li>
                    <li>
                      Translations will follow a four-step process: (1) Interpro translates into specified language; (2)
                      in-country review of translation, followed by approval or request for changes; (3) Interpro makes
                      necessary adjustments; (4) in-site placement of approved translation.
                    </li>
                    <li>
                      Translations will be achieved from the start-up English version of the site, using a simple file
                      export/import procedure involving industry standard translation files. Where existing content is
                      identified as needing translation, it gets added to a translation job that is then exported as a
                      file to be sent to the translation house. Once the translation is complete, the file is returned
                      in the same format and imported back into WordPress, which creates the foreign language
                      translations of the English content.
                    </li>
                    <li>
                      If a translation house is not required, translations can also be performed manually based on your
                      admin privileges. It is also possible to perform string translations, to quickly translate
                      commonly used copy, and WordPress admin can even display in different languages if your
                      administrators do not speak English.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <HexBG />
      </Slide>
    );
  }
}
