import React, { Component } from 'react';
import { Slide } from 'spectacle';

import Header from '../components/Header';
import HexBG from '../components/HexBG';

import Navigation from '../components/Navigation'
import Tooltip from '../components/Tooltip'
import Animation from '../components/AnimationContainer'

export default class Refreshing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false
    };
    this.handleStatus = this.handleStatus.bind(this);
  }
  handleStatus() {
    this.setState(state => ({
      showStatus: !state.showStatus
    }));
  }

  render() {
    return (
      <Slide transition={['fade']} bgColor='transparent'>
        <div className='slide03'>
          <Header />
          <Animation title="Refreshing" animation='4' />
          <Navigation prev='Status Quo' next='Hubs' />
          <Tooltip
            title="Refreshing"
            content="
            With 450,000 unique users in the last year, it's vital that the new site continues to make a strong, positive impression. Designed from the ground up, the beautiful new site will be based on Armstrong's brand standards. It will focus on ease of use and depth of content, and it will be optimized for all devices. The Hub Site will inform the design on each Regional Site, for a seamless and unique experience around the world.
            "
          />
          <button className='more-info' onClick={this.handleStatus}>
          </button>
          <p className="i">i</p>
          {this.state.showStatus ? (
            <div className='slide-in'>
              <div className='expando'>
                <div className='expando-content'>
                  <h1>Refreshing</h1>
                  <ul>
                    <li>
                      The new website structure and architecture will be designed and built to reflect the corporate
                      structure (a global hub with continental support).
                    </li>
                    <li>
                      Fairly Painless will design and prototype the new Hub Site, then apply components such as buttons,
                      navigation, icons, accordions, fonts and colors which will transfer to the design of Regional
                      Sites. In turn, these components will be striped through third-party applications, where possible,
                      such as Concept, Armstrong University, the Pipe Up! PWA, etc.
                    </li>
                    <li>
                      Subtle changes to the design of Regional Sites will be possible to accommodate language and
                      cultural differences, without affecting any other region.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <HexBG />
      </Slide>
    );
  }
}
