import React, { Component } from 'react';
import { Slide } from 'spectacle';

import Header from '../components/Header';
import HexBG from '../components/HexBG';

import Navigation from '../components/Navigation';

// import FlywheelLogo from '../assets/img/flywheel.png'
import FlywheelVideo from '../assets/img/flywheel.mp4';

export default class BestInClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false
    };
    this.handleStatus = this.handleStatus.bind(this);
  }
  handleStatus() {
    this.setState(state => ({
      showStatus: !state.showStatus
    }));
  }

  render() {
    return (
      <Slide transition={['fade']} bgColor='transparent'>
        <div className='slide02 best-in-class'>
          <Header />
          <div className='slide-input'>
            <div className='best-in-class-slide slide-title stacked'>
              <div>
                <h2>Best-In-Class Hosting</h2>
                <p>
                  It’s a big change to move your site from Armstrong servers to a dedicated, managed WordPress solution
                  and website host, Flywheel. Recently partnering with Google Cloud has allowed Flywheel to create a
                  platform that’s more scalable, resilient and faster than ever.
                </p>
              </div>
            </div>
            <div className='slide-content'>
              {/* <img alt="Flywheel" src={FlywheelLogo} /> */}
              <div className='flywheel-container'>
                <video autoPlay loop muted alt='Flywheel' src={FlywheelVideo} />
                {/* <div className="padding"></div> */}
              </div>
            </div>
            <button className='more-info' onClick={this.handleStatus}></button>
            <p className='i'>i</p>
            {this.state.showStatus ? (
              <div className='slide-in'>
                <div className='expando'>
                  <div className='expando-content'>
                    {/* <h1>Don't Look Under the Hood</h1> */}
                    <p>It's a big change, but it comes with some big benefits:</p>
                    <ul>
                      <li>
                        Frees up Armstrong’s IT department from dealing directly with the website and its server
                        maintenance.
                      </li>
                      <li>Automated daily offsite backup and one-click restore.</li>
                      <li>Full staging version of the site with one-click update.</li>
                      <li>
                        Free globalization – The site is served from 30 global locations, using a Content Delivery
                        Network (CDN), so users get served a mirrored version of the site that is geographically closest
                        to them.
                      </li>
                      <li>
                        It’s WordPress-dedicated, so their servers have the speed and security to run WordPress most
                        effectively.
                      </li>
                      <li>
                        24/7 customer service by real people based in North America, and we can attest to their quick
                        responsiveness, knowledge and courtesy. Emergency email and chat support are available and phone
                        support is accessible anywhere in the world.
                      </li>
                      <li>No caching or security plugins required.</li>
                      <li>Incredibly simple dashboard to administer the site and server.</li>
                      <li>Automatic updates to WordPress Core.</li>
                      <li>Optional automatic updates to site plugins.</li>
                      <li>
                        SSL certificate – In order to secure all the sites in the network, a Wildcard SSL certificate
                        will need to be purchased and installed.
                      </li>
                    </ul>
                    <p>
                      Armstrong’s Flywheel hosting will be owned by Armstrong with Fairly Painless added as an
                      administrator. This gives Armstrong full control over billing and full access to admin the server
                      and account, while providing Fairly Painless with access to perform updates and maintenance as
                      needed. The number of people with access to Flywheel’s Dashboard should be limited in order to
                      maintain the site effectively, however, access can be granted to anyone with a Flywheel user
                      account.
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <Navigation prev='Why Wordpress' next='Bad Guys' />
        </div>
        <HexBG />
      </Slide>
    );
  }
}
