import React, { Component } from 'react';
import { Slide } from 'spectacle';

import Navigation from '../components/Navigation';
import AnimationContainer from '../components/AnimationContainer';
import Tooltip from '../components/Tooltip';

import Header from '../components/Header';
import HexBG from '../components/HexBG';

export default class DontLookUnderTheHood extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false
    };
    this.handleStatus = this.handleStatus.bind(this);
  }
  handleStatus() {
    this.setState(state => ({
      showStatus: !state.showStatus
    }));
  }

  render() {
    return (
      <Slide transition={['fade']} bgColor='transparent'>
        <div className='slide03'>
          <Header />
          <AnimationContainer title='Under the Hood' animation='16' />
          <Navigation prev='Setting The Scene' next='Status Quo' />
          <Tooltip
            title="Don't Look Under The Hood"
            content='The current site looks good from the outside, but hidden inside are a number of fundamental functional, administrative, experiential and content issues.'
          />
          <button className='more-info' onClick={this.handleStatus}></button>
          <p className='i'>i</p>
          {this.state.showStatus ? (
            <div className='slide-in'>
              <div className='expando'>
                <div className='expando-content'>
                  <h1>Don't Look Under the Hood</h1>
                  <ul>
                    <li>
                      At the point of the last upgrade in 2015, the decision was taken to upgrade the Drupal version
                      from 5-7. At the time, there was not a great alternative for a corporate level site to Drupal,
                      without incurring great expense, so upgrading the existing platform was a sensible option.
                    </li>
                    <li>
                      While large chunks of the content on the current site have been rewritten, there are multiple
                      areas where legacy content is outdated, incomplete, off-message or redundant.
                    </li>
                    <li>The product section is not easy to navigate and finding specific products can be difficult.</li>
                    <li>
                      CRM and Webforms do not function properly for their purpose, leading to missing information being
                      received or incorrectly delivered.
                    </li>
                    <li>
                      The current CMS is very difficult to update and manage, which means that even simple content
                      updates must often be completed by an outside developer, rather than by an Armstrong employee.
                    </li>
                    <li>
                      A website rebrand and update in 2015 brought some new life to the site, but this was diminished by
                      dragging all existing content and existing with it.
                    </li>
                    <li>
                      Making changes can be frustrating because speed is hindered by having too many cooks in the
                      kitchen, all performing various tasks – site admins, CIU, Fairly Painless and Rapid Development
                      Group.
                    </li>
                    <li>Staying with Drupal long term will be as expensive as converting to the Multisite.</li>
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <HexBG />
      </Slide>
    );
  }
}
