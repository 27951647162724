import React, { Component } from 'react';
import { Slide } from 'spectacle';

import Header from '../components/Header';
import HexBG from '../components/HexBG';

import Tooltip from '../components/Tooltip'
import Navigation from '../components/Navigation'
import Animation from '../components/AnimationContainer'

export default class LetsBringItWithUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false
    };

    this.handleStatus = this.handleStatus.bind(this);
  }
  handleStatus() {
    this.setState(state => ({
      showStatus: !state.showStatus
    }));
  }

  render() {
    return (
      <Slide transition={['fade']} bgColor='transparent'>
        <div className='slide03'>
          <Header />
          <Animation title="Let's Bring it with us" animation="9" />
          <Navigation prev="Where in the World" next="Let's not Bring it" />
          <Tooltip
            title="Let's Bring it With Us"
            content="
            Recently developed content, based on industry-focused verticals and customer pain points, that has been applied across the current site will be moved over to the new site. We won’t throw the baby out with the bathwater.
            "
          />
          <button className='more-info' onClick={this.handleStatus}>
          </button>
          <p className="i">i</p>
          {this.state.showStatus ? (
            <div className='slide-in'>
              <div className='expando'>
                <div className='expando-content'>
                  <h1>Let's Bring It With Us</h1>
                  <p>
                    Examples include: Food & Beverage, Healthcare, Data Centers, Agribusiness, Hot Water for People,
                    Trap Management Services, SAGE® by Armstrong, and more.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <HexBG />
      </Slide>
    );
  }
}
