import React, { Component } from 'react';
import { Slide } from 'spectacle';

import Header from '../components/Header';
import HexBG from '../components/HexBG';

import Tooltip from '../components/Tooltip'
import Navigation from '../components/Navigation'
import Animation from '../components/AnimationContainer'

export default class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false
    };
    this.handleStatus = this.handleStatus.bind(this);
  }
  handleStatus() {
    this.setState(state => ({
      showStatus: !state.showStatus
    }));
  }

  render() {
    return (
      <Slide transition={['fade']} bgColor='transparent'>
        <div className='slide04'>
          <Header />
          <Animation title="Products" animation="11" />
          <Navigation prev="Let's Not Bring it All" next="Keeping the site alive" />
          <Tooltip
            title="Products - A Curated User Experience"
            content='
              The restructuring of content required by a move from Drupal to WordPress gives us the opportunity to clean and rethink the way Armstrong’s products work on the web. Moving to a more “Amazon-like” experience with sophisticated filters and sorting allows users to essentially curate their own view, giving them a much
              faster and friendlier user experience.
            '
          />
          <button className='more-info' onClick={this.handleStatus}>
          </button>
          <p className="i">i</p>
          {this.state.showStatus ? (
            <div className='slide-in'>
              <div className='expando'>
                <div class='expando-content'>
                  <h1>Products – A Curated User Experience</h1>
                  <ul>
                    <li>
                      Filters should be modeled after the actual behavior of users – similar to how a customer would ask
                      a Rep for a product. The language used to model these choices would be reflected in the filter.
                      Category-specific filters provide the added advantage of serving as an education point for people
                      who have less experience with the content being displayed.
                    </li>
                    <li>
                      On the admin side, Armstrong can control categorization and tagging of products, so that products
                      are easily moved around and edited using zero code. Products will live on the Hub Site, and be
                      pushed out to Regional Sites, giving full control to super admins over which regions see which
                      products, by default. This also means that there will only be a single English version of each
                      product, initially, striped around any networked sites, with translations of those handled by the
                      admins or translation managers on the Regional Sites.
                    </li>
                    <li>
                      End users viewing a Region would be served the default product offering available in their area,
                      with an option to turn that filter off and see all products. Products unavailable in their region
                      would be flagged as such, but users would be allowed to see everything in the global catalog.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <HexBG />
      </Slide>
    );
  }
}
