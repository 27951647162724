import React, { Component } from 'react';
import { Slide } from 'spectacle';

import Header from '../components/Header';
import HexBG from '../components/HexBG';

import Navigation from '../components/Navigation';

export default class EnterThePortal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false
    };
    this.handleStatus = this.handleStatus.bind(this);
  }
  handleStatus() {
    this.setState(state => ({
      showStatus: !state.showStatus
    }));
  }

  render() {
    return (
      <Slide transition={['fade']} bgColor='transparent'>
        <div className='slide02'>
          <Header />
          <div className='slide-input'>
            <div className='slide-title stacked'>
              <div>
                <h2>Enter the Portal</h2>
              </div>
            </div>
            <div className='slide-content'>
              <p>
                Armstrong’s new User Portals will be individualized by user, giving a dramatically enhanced level of
                personalized content and targeting for sales and marketing purposes. The portals will leverage
                Salesforce’s capabilities to create a rich dashboard experience for registered site users.
              </p>
            </div>
          <button className='more-info' onClick={this.handleStatus}>
          </button>
          <p className="i">i</p>
            {this.state.showStatus ? (
              <div className='slide-in'>
                <div className='expando'>
                  <div className='expando-content'>
                    <p>
                      Hub Site admins will have the ability to assign blocks of content to a user's portal. These blocks
                      may be pre-wired widgets, such as "recent orders" or "find a rep" or custom blocks containing
                      editable content.
                    </p>

                    <p>
                      What a user sees on their portal will be controlled by agreed criteria, such as, location,
                      industry, user type etc. Admins can then show all Healthcare users in India a specific piece of
                      content, for example.
                    </p>

                    <p>
                      The list of pre-wired content blocks available to place on user portals will have to be
                      established well in advance of the build to ensure correct integration with the various back-end
                      systems involved.
                    </p>

                    <p>
                      A user survey of customers, reps and distributors to establish what they currently use on their
                      portal, what is redundant, and what they would like to see may provide good intelligence on this
                      topic.
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <Navigation prev='Lots of docs' next='SEO' />
        </div>
        <HexBG />
      </Slide>
    );
  }
}
