import React, { Component } from 'react';
import { Slide } from 'spectacle';

import Header from '../components/Header';
import HexBG from '../components/HexBG';

import Tooltip from '../components/Tooltip'
import Navigation from '../components/Navigation'
import Animation from '../components/AnimationContainer'

export default class LotsOfDocs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false
    };
    this.handleStatus = this.handleStatus.bind(this);
  }
  handleStatus() {
    this.setState(state => ({
      showStatus: !state.showStatus
    }));
  }

  render() {
    return (
      <Slide transition={['fade']} bgColor='transparent'>
        <div className='slide03'>
          <Header />
          <Animation title="Lots and Lots of Docs" animation="15" />
          <Navigation prev="Salesforce" next="Enter the portal" />
          <Tooltip
            title="Lots and Lots of Docs"
            content="
            Armstrong’s Resource Library currently contains 11,088 files. It requires a document management solution that will enable it to function into the future by providing better search capabilities, administration functions, and speed.
            "
          />
          <button className='more-info' onClick={this.handleStatus}>
          </button>
          <p className="i">i</p>
          {this.state.showStatus ? (
            <div className='slide-in'>
              <div className='expando'>
                <div className='expando-content'>
                  <h1>Lots and Lots of Docs</h1>
                  <ul>
                    <li>
                      Before bringing all those files into the new site, any out-of-date, unpublished or unwanted files
                      should be identified and purged on the Drupal site. The less we migrate, the better the speed and
                      efficiency of the site.
                    </li>
                    <li>
                      Once purged, files can be downloaded in a dump file and then uploaded via sftp to the new server
                      on Flywheel. Once imported into our document management plugin (likely a WP File Download), they
                      will become available to admins to categorize and tag. WP File Download provides much more control
                      over how files are organized, with folders, categories and admin rights all assignable.
                    </li>
                    <li>
                      Admins will be able to replace existing files with new versions and also store old versions if
                      required.
                    </li>
                    <li>
                      A download statistics dashboard is available from the WordPress admin, and additionally tracked by
                      a Google Analytics event.
                    </li>
                    <li>
                      When accessing the Resource Library, front-end users will benefit from a simple user interface and
                      a vastly improved search that includes a full text search of certain files, such as .doc and
                      .pdfs.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <HexBG />
      </Slide>
    );
  }
}
