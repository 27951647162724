import React, { Component } from 'react';
import { Slide } from 'spectacle';

import Header from '../components/Header';
import HexBG from '../components/HexBG';

import Animation from '../components/AnimationContainer'
import Navigation from '../components/Navigation'
import Tooltip from '../components/Tooltip'

export default class Hubs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false
    };

    this.handleStatus = this.handleStatus.bind(this);
  }
  handleStatus() {
    this.setState(state => ({
      showStatus: !state.showStatus
    }));
  }

  render() {
    return (
      <Slide transition={['fade']} bgColor='transparent'>
        <div className='slide03'>
          <Header />
          <Animation title='Hubs' animation="5" />
          <Navigation prev='Refreshing' next='Why Wordpress' />
          <Tooltip
          title="Hubs & Spokes"
          content="
            Multisite is a WordPress feature which allows us to create a network of sites on a single WordPress installation. The Hub Site parent (Armstrong's primary site) controls and feeds its children, the 5 Regional Sites.
          "
          />
          <button className='more-info' onClick={this.handleStatus}>i</button>
          {this.state.showStatus ? (
            <div className='slide-in'>
              <div className='expando'>
                <div className='expando-content'>
                  <h1>Hubs & Spokes</h1>
                  <p>WordPress Multisite network features:</p>
                  <ul>
                    <li>Run a network of multiple websites from a single WordPress installation.</li>
                    <li>Each region can have its own messaging and default product mix.</li>
                    <li>
                      Each Regional Site can have its own version of the site design and layout, allowing for subtle
                      differences across regions to facilitate various languages and cultures.
                    </li>
                    <li>
                      As a super admin, you can install themes and plugins and make them available to all other sites on
                      the network, while at the same time restricting normal site admins to ownership of their own site.
                    </li>
                    <li>
                      The Multisite network provides centralized control with localized flexibility by allowing tight
                      control of shared content from the central Hub Site. Admins can specify the products, case
                      studies, events and other content to be displayed on their Regional Site. Admins in the Hub Site
                      will be able to categorize all products by region. Based on that categorization, the product may
                      or may not be viewed by an end user in that region.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <HexBG />
      </Slide>
    );
  }
}
