import React, { Component } from 'react';
import { Slide } from 'spectacle';

import Navigation from '../components/Navigation'
import AnimationContainer from '../components/AnimationContainer'
import Tooltip from '../components/Tooltip'

import Header from '../components/Header';
import HexBG from '../components/HexBG';

export default class StatusQuo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false
    };
    this.handleStatus = this.handleStatus.bind(this);
  }
  handleStatus() {
    this.setState(state => ({
      showStatus: !state.showStatus
    }));
  }

  render() {

    return (
      <Slide transition={['fade']} bgColor='transparent'>
        <div className='slide03'>
          <Header />
          <AnimationContainer title='Status Quo' animation='3' />
          <Navigation prev="Under The Hood" next="Refreshing" />
          <Tooltip
            title="From Status Quo to Evolution"
            content="The new Armstrong International website is a fresh start, with a powerful new CMS and a network of sites to accommodate the way Armstrong plans to do business over the next decade. The new website aligns strategically with Armstrong’s new continental focus."
          />
          <button className='more-info' onClick={this.handleStatus}>
          </button>
          <p className="i">i</p>
          {this.state.showStatus ? (
            <div className='slide-in'>
              <div className='expando'>
                <div className='expando-content'>
                  <h1>From Status Quo To Evolution</h1>
                  <p>
                    The web moves very quickly and it’s easy to get left behind in older technology, especially when
                    it’s integrated with legacy systems and customizations. That’s where we find ourselves with Drupal,
                    the Content Management System (CMS) that armstronginternational.com is built upon – each upgrade and
                    edit often requires expensive “hacks” and bespoke development to move forward.
                  </p>
                  <p>
                    The site is currently built in Drupal 7. Drupal 8 has had very low uptake, and as a result, the
                    development community does not widely support it in terms of module development and bolt-on
                    functionality. This also means that developers capable of working on the platform will become more
                    scarce and any future development within Drupal may become cost prohibitive.
                  </p>
                  <p>
                    In contrast, WordPress has evolved to become by far the world’s biggest CMS, powering around 40% of
                    all sites and 60% of all CMS. Finding development support is very easy and it is a platform very
                    familiar to most admins.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <HexBG />
      </Slide>
    );
  }
}
