import React, { Component } from 'react';
import { Slide } from 'spectacle';

import Header from '../components/Header';
import HexBG from '../components/HexBG';

import Navigation from '../components/Navigation';

export default class ArmstrongUniversity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false
    };
    this.handleStatus = this.handleStatus.bind(this);
  }
  handleStatus() {
    this.setState(state => ({
      showStatus: !state.showStatus
    }));
  }

  render() {
    return (
      <Slide transition={['fade']} bgColor='transparent'>
        <div className='slide02'>
          <Header />
          <div className='slide-input'>
            <div className='slide-title stacked'>
              <div>
                <h2>Armstrong University</h2>
              </div>
            </div>
            <div className='slide-content'>
              <p>
                Our recommendation is to keep Armstrong University separate from the Multisite WordPress-based website.
                Choosing and participating in multimedia course content is best handled by a dedicated LMS, especially
                with the number of courses and the amount of content contained within Armstrong University.
              </p>
            </div>
          <button className='more-info' onClick={this.handleStatus}>
          </button>
          <p className="i">i</p>
            {this.state.showStatus ? (
              <div className='slide-in'>
                <div className='expando'>
                  <div className='expando-content'>
                    <p>
                      Our current perspective on this has led to a few scenarios that would require further discussion
                      to ensure the relevant stakeholders have their say. There are many ways the upgrade to Armstrong
                      University could be accomplished, but the following are some possibilities from our research:
                    </p>
                    <ul>
                      <li>
                        The current setup of Course Merchant and Moodle that handles Armstrong University's
                        functionality could remain in place, as-is. The new site could likely be integrated with Moodle
                        via Moodle’s API to bring in course data, dynamically, to populate some kind of catalog or
                        listing on the site
                      </li>
                      <li>
                        Armstrong moves its LMS to a third-party solution that can handle both the shopping cart and
                        Moodle functionality to deliver courses, removing the need for Course Merchant. Depending on the
                        chosen solution, it may be possible to deeper integrate the course data into the WordPress site.
                      </li>
                      <li>
                        Armstrong moves its LMS into a Salesforce LMS solution. This has some exciting advantages around
                        being very closely tied to the customer base and the rich data available within Salesforce about
                        all your students. Once again, it should be possible to integrate course information pulled from
                        the Salesforce LMS with the corporate site, to give a richer user experience for people browsing
                        courses on armstronginternational.com, but would depend on the functionality provided with the
                        LMS solution. This would be our recommendation in terms of future direction.
                      </li>
                    </ul>
                    <p>
                      Whichever direction is chosen, there is a fairly urgent need to convert or rebuild large chunks of
                      current courses, due to their reliance on Flash animations and interactions, which will soon
                      become entirely unusable in their current format.
                    </p>
                    <p>
                      We would also reskin whatever solution gets the green light, to bring it into the same family as
                      any proposed design for the main site.
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <Navigation prev='Accessibility' next='Other Worlds' />
        </div>
        <HexBG />
      </Slide>
    );
  }
}
