import React from 'react';
import { Slide } from 'spectacle';

import Header from '../components/Header';
import HexBG from '../components/HexBG';

import Navigation from '../components/Navigation'

export default function HowLong() {
  return (
    <Slide transition={['fade']} bgColor='transparent'>
      <div className='slide02'>
        <Header />
        <div className='slide-input'>
          <div className='slide-title stacked'>
            <div>
              <h2>How Long WIll It All Take?</h2>
              {/* <p>
                A number of other sites and tools exist outside of the WordPress and Salesforce galaxies. We won’t
                forget them.
              </p> */}
            </div>
          </div>
          <div className='slide-content'>
            <p>
              This will require a great deal of work, involving the input and expertise of many people. We expect it to
              take 39 – 52 weeks.
            </p>
          </div>
      </div>
        <Navigation prev="Other Worlds" next="Maintenance" />
      </div>
      <HexBG />
    </Slide>
  );
}
