import React, { Component } from 'react';
import { Slide } from 'spectacle';

import Header from '../components/Header';
import HexBG from '../components/HexBG';

import Tooltip from '../components/Tooltip'
import Navigation from '../components/Navigation'
import Animation from '../components/AnimationContainer'

export default class LetsNotBringIt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false
    };
    this.handleStatus = this.handleStatus.bind(this);
  }
  handleStatus() {
    this.setState(state => ({
      showStatus: !state.showStatus
    }));
  }

  render() {

    return (
      <Slide transition={['fade']} bgColor='transparent'>
        <div className='slide03'>
          <Header />
          <Animation title="Let's Not Bring It All" animation="10" />
          <Navigation prev="Let's Bring it All" next="Products" />
          <Tooltip
            title="Let's Not Bring it All"
            content="
            Our emphasis throughout this project will be on achieving a nimble, streamlined and best-in-class website for both customers and admins to use effectively. The existing site is burdened with legacy content, old documents, unpublished pages, and lots more. Let’s not bring all that with us.
            "
          />
          <button className='more-info' onClick={this.handleStatus}>
          </button>
          <p className="i">i</p>
          {this.state.showStatus ? (
            <div className='slide-in'>
              <div className='expando'>
                <div className='expando-content'>
                  <h1>
                    Let's Not Bring It <em>All</em> With Us
                  </h1>
                  <ul>
                    <li>
                      It is our recommendation that we do not try to carry everything over to the new site, as the
                      housekeeping involved is impractical and inefficient. Let's start fresh, using only relevant,
                      fit-for-purpose content.
                    </li>
                    <li>
                      Where we do need to bring content over, the goal is to automate as much migration as possible.
                    </li>
                    <li>
                      The existing product catalog is a great example of semi-automating the population of a new product
                      catalog. Using existing spreadsheets containing Armstrong product information, we are able to
                      import much of the product data into WordPress, cutting down on copy/paste and typing, and
                      reducing inaccuracies. This process essentially creates product pages, within WordPress via a file
                      import.
                    </li>
                    <li>
                      Many of the existing product descriptions are outdated or nonexistent, so there will be a good
                      amount of content input and copywriting required for the products, and also around the product
                      families and categories. It is anticipated that a great deal of the product categorization and
                      tagging would be done by Armstrong in a spreadsheet that would then be imported to auto-create the
                      products and their categories/tags.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <HexBG />
      </Slide>
    );
  }
}
