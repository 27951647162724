import React, { Component } from 'react';
import { Slide } from 'spectacle';

import Header from '../components/Header';
import HexBG from '../components/HexBG';

import Animation from '../components/AnimationContainer'
import Navigation from '../components/Navigation'
import Tooltip from '../components/Tooltip'

export default class TheStrategy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false
    };
    this.handleStatus = this.handleStatus.bind(this);
  }
  handleStatus() {
    this.setState(state => ({
      showStatus: !state.showStatus
    }));
  }

  render() {
    return (
      <Slide transition={['fade']} bgColor='transparent'>
        <div className='slide03'>
          <Header />
          <Animation title="The Strategy" animation="1" />
          <Navigation prev="Home" next="Setting The Scene" />
          <Tooltip
            title="The Strategy"
            content="The new Armstrong International site will be rebuilt from the ground up, using best practices in all aspects of the design and development and seamlessly fitting into Armstrong’s Strategy Pipeline."
          />
          <button className='more-info' onClick={this.handleStatus}>
          </button>
          <p className="i">i</p>
          {this.state.showStatus ? (
            <div className='slide-in'>
              <div className='expando'>
                <div className='expando-content'>
                  <h1>The Strategy</h1>
                  <p>
                    The new Armstrong International site will be rebuilt from the ground up, using best practices in all
                    aspects of the design and development and seamlessly fitting into Armstrong’s Strategy Pipeline.
                  </p>
                  <p>
                    Beginning from scratch, as if Armstrong International had no website, we will create a new look,
                    factoring in content and functional requirements. The 2015 version of the site was essentially a
                    facelift or design upgrade of the previous site and has resulted in a series of bandaids being
                    applied ever since. It’s time for an overhaul.
                  </p>
                  <p>
                    Content will continue to be driven by the industry focus recently implemented by Armstrong, while
                    also incorporating analytics, SEO and user testing.
                  </p>

                  <p>
                    The new site will provide the best possible user experience and interface for end users and site
                    administrators alike, making rich, dynamic, easily updatable content available to all. Control of
                    content will be in the hands of site admins almost completely.
                  </p>

                  <p>
                    From a development standpoint, the goal is to remove or eliminate as much bespoke wiring as
                    possible, using established plugins where necessary to achieve specific functionality and standard
                    API’s to move data around.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <HexBG />
      </Slide>
    );
  }
}
