import React from 'react';
import { Slide } from 'spectacle';

import Header from '../components/Header';
import HexBG from '../components/HexBG';

import Navigation from '../components/Navigation'

export default function Takeaway() {
  return (
    <Slide transition={['fade']} bgColor='transparent'>
      <div className='slide02 takeaway-slide'>
        <Header />
        <div className='slide-input'>
          <div className='slide-title stacked'>
            <div>
              <h2>Your Takeaway</h2>
              <p>
                It's always nice to leave a lasting impression so we created this presentation as a Progressive Web App
                (PWA) that will work on any device, just like any app. This way, you can get comfortable, put your feet
                up and get into all the details
              </p>
            </div>
          </div>
          <div className='slide-content'>
            <a href='https://armstrongofthefuture.com'>www.armstrongofthefuture.com</a>
            <p>Save to your mobile device's desktop and open as an app.</p>
          </div>
        </div>
        <Navigation prev="Maintenance" />
      </div>
      <HexBG />
    </Slide>
  );
}
