import React, { Component } from 'react';

export default class Navigation extends Component {
  render() {
    return (
      <>
        <div className='slide-navigation slide-prev'>
          <h5>{this.props.prev}</h5>
        </div>
        <div className='slide-navigation slide-next'>
          <h5>{this.props.next}</h5>
        </div>
      </>
    );
  }
}
