import React, { Component } from 'react';
import { Slide } from 'spectacle';

import Header from '../components/Header';
import HexBG from '../components/HexBG';

import Tooltip from '../components/Tooltip'
import Navigation from '../components/Navigation'
import Animation from '../components/AnimationContainer'

export default class BadGuys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false
    };

    this.handleStatus = this.handleStatus.bind(this);
  }
  handleStatus() {
    this.setState(state => ({
      showStatus: !state.showStatus
    }));
  }

  render() {
    return (
      <Slide transition={['fade']} bgColor='transparent'>
        <div className='slide03'>
          <Header />
          <Animation title="Bad Guys" animation="7" />
          <Navigation prev="Best in Class" next="Where in the World" />
          <Tooltip
            title="Keep the Bad Guys Away"
            content="
            Flywheel’s platform is designed to battle hackers with actively managed, WordPress-specific security.
            "
          />

          <button className='more-info' onClick={this.handleStatus}>
          </button>
          <p className="i">i</p>
          {this.state.showStatus ? (
            <div className='slide-in'>
              <div className='expando'>
                <div className='expando-content'>
                  <h1>Keep the Bad Guys Away</h1>
                  <ul>
                    <li>No need for any security plugins, it’s all handled at server level.</li>
                    <li>
                      If the site does get hacked, malware is removed quickly and carefully by experts, at no additional
                      charge.
                    </li>
                    <li>Option to activate to two-factor authentication for site admins, if required.</li>
                    <li>Login attempts are limited at both server and site level.</li>
                    <li>
                      Intelligent IP blocking detects intruders and blocks them across all Flywheel sites within
                      seconds.
                    </li>
                    <li>
                      All core updates are handled by Flywheel to ensure we are always on the most secure and up-to-date
                      version.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <HexBG />
      </Slide>
    );
  }
}
