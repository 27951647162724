import React, { Component } from 'react'

export default class Animation extends Component {
  render() {
    return (
      <div className='iframe-container'>
        <iframe title={this.props.title} src={'https://armanisrcs.netlify.com/' + this.props.animation} />
      </div>
    )
  }
}
