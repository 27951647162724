import React, { Component } from 'react';

export default class Tooltip extends Component {
  render() {
    return (
      <>
        <div className='expando tooltip'>
          <h1 className={this.props.class}>{this.props.title}</h1>
          <p>{this.props.content}</p>
        </div>
      </>
    );
  }
}
