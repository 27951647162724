import React, { Component } from 'react';
import { Slide } from 'spectacle';

import Header from '../components/Header';
import HexBG from '../components/HexBG';

import Navigation from '../components/Navigation';

export default class SEO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false
    };
    this.handleStatus = this.handleStatus.bind(this);
  }
  handleStatus() {
    this.setState(state => ({
      showStatus: !state.showStatus
    }));
  }
  render() {
    return (
      <Slide transition={['fade']} bgColor='transparent'>
        <div className='slide02'>
          <Header />
          <div className='slide-input'>
            <div className='slide-title stacked'>
              <div>
                <h2>SEO Best Practices</h2>
              </div>
            </div>
            <div className='slide-content'>
              <p>
                As search and SEO continue to evolve rapidly across the web, branching into an A.I. dominated search
                future and platforms other than Google, we need to build the site with these things in mind.
              </p>
            </div>
          <button className='more-info' onClick={this.handleStatus}>
          </button>
          <p className="i">i</p>
            {this.state.showStatus ? (
              <div className='slide-in'>
                <div className='expando'>
                  <div className='expando-content'>
                    <p>
                      The following high-level recommendations should be top of mind during the design, development and
                      content creation pieces of the build:
                    </p>
                    <ul>
                      <li>
                        There are increasingly fewer ways to “game” search engines, which is an advantage for
                        organizations developing well-written and engaging content, such as Armstrong. Google actively
                        rewards sites it identifies as having exceptional depth in quality content – not just writing
                        words, but writing content that moves, motivates, connects and informs.
                      </li>
                      <li>
                        Having a good grasp on what your users want, need and expect is key to driving content, and
                        therefore attracting the right users to spend longer on the site. The more quality users on the
                        site, the better for SEO.
                      </li>
                      <li>
                        A.I. is at the forefront of crawling sites. Ensuring that our pages are built using structured
                        data/Schema wherever possible gives these A.I.s the ability to crawl the site easily, allowing
                        us to improve our SEO scores. The speed the site loads is also key.
                      </li>
                      <li>
                        Being aware of voice search in the way a page is built is gaining importance. This is part of a
                        general shift from “results” to “answers” and is typically a more personalized approach to
                        search. Again, this has a bearing on how the written word appears on the page.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <Navigation prev='Enter the Portal' next='Accessibility' />
        </div>
        <HexBG />
      </Slide>
    );
  }
}
