import React, { Component } from 'react';
import { Slide } from 'spectacle';

import Header from '../components/Header';
import HexBG from '../components/HexBG';

import Navigation from '../components/Navigation';

export default class Accessibility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false
    };
    this.handleStatus = this.handleStatus.bind(this);
  }
  handleStatus() {
    this.setState(state => ({
      showStatus: !state.showStatus
    }));
  }

  render() {
    return (
      <Slide transition={['fade']} bgColor='transparent'>
        <div className='slide02'>
          <Header />
          <div className='slide-input'>
            <div className='slide-title stacked'>
              <div>
                <h2>Accessibility</h2>
              </div>
            </div>
            <div className='slide-content'>
                <p>
                  This list comes from WebAIM’s Principles of Accessible Design and follows many best practices for web
                  accessibility that will be adhered to during the build.
                </p>
            </div>

          <button className='more-info' onClick={this.handleStatus}>
          </button>
          <p className="i">i</p>
            {this.state.showStatus ? (
              <div className='slide-in'>
                <div className='expando'>
                  <div className='expando-content'>
              <ol>
                <li>
                  <strong>Provide appropriate alternative text</strong>
                  Alternative text provides a textual alternative to non-text content (such as pictures and images) in
                  web pages. It is especially helpful for people who are blind and rely on a screen reader to have the
                  content of the website read to them.
                </li>
                <li>
                  <strong>Provide appropriate document structure</strong>
                  Headings, lists, and other structural elements provide meaning and structure to web pages. They can
                  also facilitate keyboard navigation within the page.
                </li>
                <li>
                  <strong>Provide headers for data tables</strong>
                  Tables are used online for layout and to organize data. Tables that are used to organize tabular data
                  should have appropriate table headers (the {'<th>'} element). Data cells should be associated with
                  their appropriate headers, making it easier for screen reader users to navigate and understand the
                  data table.
                </li>
                <li>
                  <strong>Ensure users can complete and submit all forms</strong>
                  Ensure that every form element (text field, checkbox, dropdown list, etc.) has a label and that every
                  label is associated with the correct form element using the {'<label>'} element. Also make sure the
                  user can submit the form and recover from any errors, such as the failure to fill in all required
                  fields.
                </li>
                <li>
                  <strong>Ensure links make sense out of context</strong>
                  Every link should make sense if the link text is read by itself. Screen reader users may choose to
                  read only the links on a web page.
                </li>
                <li>
                  <strong>Make JavaScript accessible</strong>
                  Ensure that JavaScript event handlers are device independent (e.g., they do not require the use of a
                  mouse).
                </li>
                <li>
                  <strong>Design to standards</strong>
                  HTML compliant and accessible pages are more robust and provide better search engine optimization. The
                  use of color can enhance comprehension, but do not use color alone to convey information. That
                  information may not be available to a person who is colorblind and will be unavailable to screen
                  reader users. Write clearly, use clear fonts, and use headings and lists appropriately.
                </li>
              </ol>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <Navigation prev='SEO' next='Armstrong University' />
        </div>
        <HexBG />
      </Slide>
    );
  }
}
