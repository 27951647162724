import React, { Component } from 'react';
import { Slide } from 'spectacle';

import HexBG from '../components/HexBG';
import logo from '../assets/img/logo.png';

export default class Slide01 extends Component {
  render() {
    return (
      <Slide transition={['fade']} bgColor='transparent'>
        <div className='slide01'>
          <div className='main-title'>
            <img className='slide-logo' alt='Armstrong International' src={logo} />
            <div className='home-page-title' style={{ paddingTop: '8px'}}>
              <h1>The Next Big Thing:</h1>
              <h2>Armstrong's Online Future</h2>
            </div>
          </div>

          <div className='slide-navigation slide-next'>
            <h5>The Strategy</h5>
          </div>
        </div>

        <HexBG />
      </Slide>
    );
  }
}
