import React, { Component } from 'react'
import logo from '../assets/img/logo.png'

export default class Header extends Component {
  render() {
    return (

        <div className='main-title top-left'>

      <a style={{color: 'initial'}} href="/">
          <img className='slide-logo' alt='Armstrong International' src={logo} />
      </a>
          <div>
            <h1>The Next Big Thing:</h1>
            <h2>Armstrong's Online Future</h2>
          </div>
        </div>
    )
  }
}
