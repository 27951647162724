import React from 'react';
import { Deck } from 'spectacle';

// Import theme
import createTheme from 'spectacle/lib/themes/default';
import './app.css';

import RotateDevice from './assets/img/rotate.gif'

// Import Slides
import Home from './slides/Home'
import TheStrategy from './slides/TheStrategy'
import SettingTheScene from './slides/SettingTheScene'
import DontLookUnderTheHood from './slides/DontLookUnderTheHood'
import StatusQuo from './slides/StatusQuo'
import Refreshing from './slides/Refreshing'
import Hubs from './slides/Hubs'
import WhyWordPress from './slides/WhyWordPress'
import BestInClass from './slides/BestInClass'
import BadGuys from './slides/BadGuys'
import WhereInTheWorld from './slides/WhereInTheWorld'
import LetsBringItWithUs from './slides/LetsBringItWithUs'
import LetsNotBringIt from './slides/LetsNotBringIt'
import Products from './slides/Products'
import KeepAlive from './slides/KeepAlive'
import WordOnTheStreet from './slides/WordOnTheStreet'
import Salesforce from './slides/Salesforce'
import LotsOfDocs from './slides/LotsOfDocs'
import EnterThePortal from './slides/EnterThePortal'
import SEO from './slides/SEO'
import Accessibility from './slides/Accessibility'
import ArmstrongUniversity from './slides/ArmstrongUniversity'
import OtherWorlds from './slides/OtherWorlds'
import HowLong from './slides/HowLong'
// import TheCost from './slides/TheCost'
import Takeaway from './slides/Takeaway'
import Maintenance from './slides/Maintenance'

require('normalize.css');

const theme = createTheme(
  {
    primary: 'white',
    secondary: '#1F2022',
    tertiary: '#03A9FC',
    quaternary: '#e7b820'
  },
  {
    primary: 'Trade Gothic',
    secondary: 'Proxima Nova'
  }
);

export default class Presentation extends React.Component {
  render() {
    return (
      <>

      <div className="portrait-message">
        Please rotate your device.
        <img alt="rotate" src={RotateDevice} />
      </div>

      <Deck
        disableTouchControls={false}
        controls={true}
        progress={'none'}
        transition={['fade']}
        transitionDuration={500}
        theme={theme}
        showFullscreenControl={false}
        >

        <Home />
        <TheStrategy />
        <SettingTheScene />
        <DontLookUnderTheHood />
        <StatusQuo />
        <Refreshing />
        <Hubs />
        <WhyWordPress />
        <BestInClass />
        <BadGuys />
        <WhereInTheWorld />
        <LetsBringItWithUs />
        <LetsNotBringIt />
        <Products />
        <KeepAlive />
        <WordOnTheStreet />
        <Salesforce />
        <LotsOfDocs />
        <EnterThePortal />
        <SEO />
        <Accessibility />
        <ArmstrongUniversity />
        <OtherWorlds />
        <HowLong />
        {/* <TheCost /> */}
        <Maintenance />
        <Takeaway />

      </Deck>
      </>
    );
  }
}
