import React, { Component } from 'react';
import { Slide } from 'spectacle';

import Header from '../components/Header';
import HexBG from '../components/HexBG';

import Tooltip from '../components/Tooltip'
import Navigation from '../components/Navigation'
import Animation from '../components/AnimationContainer'

export default class Salesforce extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false
    };
    this.handleStatus = this.handleStatus.bind(this);
  }
  handleStatus() {
    this.setState(state => ({
      showStatus: !state.showStatus
    }));
  }

  render() {
    return (
      <Slide transition={['fade']} bgColor='transparent'>
        <div className='salesforce'>
          <Header />
          <Animation title="Salesforce" animation="14" />
          <Navigation prev="Word on the Street" next="Lots of Docs" />
          <Tooltip
            title="Salesforce Leads the Way to Lead Generation"
            content="
              Armstrong’s move to Salesforce allows the new site to easily integrate with the new Salesforce CRM and Pardot marketing platform.
            "
          />
          <button className='more-info' onClick={this.handleStatus}>
          </button>
          <p className="i">i</p>
          {this.state.showStatus ? (
            <div className='slide-in'>
              <div className='expando'>
                <div className='expando-content'>
                  <h1>Salesforce Leads the Way to Lead Generation</h1>
                  <ul>
                    <li>
                      Forms can be built using Pardot or FormTitan and simply embedded on the site. Certain forms may
                      need deeper integration, such as the registration form, which will need to store passwords using
                      LDAP. Those forms can use hooks and form actions available in FormTitan to connect as required to
                      Armstrong’s LDAP server, or wherever they need to connect outside WordPress, Salesforce and
                      Pardot.
                    </li>
                    <li>
                      Tracking codes and code snippets for Pardot and Salesforce can be copied and pasted into the
                      Multisite as required; admins will have full control over this.
                    </li>
                    <li>
                      Further integration with Salesforce’s established and custom API’s will allow the new site to
                      display personalized user content on user portals.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <HexBG />
      </Slide>
    );
  }
}
