import React, { Component } from 'react';
import { Slide } from 'spectacle';

import Header from '../components/Header';
import HexBG from '../components/HexBG';

import Animation from '../components/AnimationContainer'
import Navigation from '../components/Navigation'
import Tooltip from '../components/Tooltip'

export default class SettingTheScene extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false
    };
    this.handleStatus = this.handleStatus.bind(this);
  }
  handleStatus() {
    this.setState(state => ({
      showStatus: !state.showStatus
    }));
  }

  render() {

    return (
      <Slide transition={['fade']} bgColor='transparent'>
        <div className='slide03'>
          <Header />
          <Animation title="Setting the Scene" animation="2" />
          <Navigation prev="The Strategy" next="Don't look Under the Hood" />
          <Tooltip title="Setting the Scene" content="It's good to know the basics before we start." />
        </div>
        <HexBG />
      </Slide>
    );
  }
}
